import { createIcon } from '@chakra-ui/react';

export const FuneralIcon = createIcon({
  displayName: 'FuneralIcon',
  viewBox: '0 0 40 40',
  path: [
    <rect
      key="1"
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="19.5"
      fill="none"
      stroke="#146CFA"
    />,
    <path
      key="2"
      d="M27.5 12.5C26.3586 12.499 25.2295 12.7363 24.185 13.1966C22.7694 10.8182 20.4425 9.63411 20.3356 9.5788C20.2314 9.52666 20.1165 9.49951 20 9.49951C19.8835 9.49951 19.7686 9.52666 19.6644 9.5788C19.5575 9.63317 17.2269 10.8182 15.815 13.1966C14.7705 12.7363 13.6414 12.499 12.5 12.5C12.3011 12.5 12.1103 12.5791 11.9697 12.7197C11.829 12.8604 11.75 13.0511 11.75 13.25V17C11.7525 19.0575 12.5227 21.0399 13.9098 22.5594C15.297 24.0789 17.2013 25.026 19.25 25.2154V28.5369L15.8356 26.8288C15.7475 26.7847 15.6515 26.7584 15.5532 26.7515C15.4549 26.7445 15.3562 26.7569 15.2627 26.7881C15.1692 26.8192 15.0827 26.8685 15.0083 26.9331C14.9338 26.9977 14.8728 27.0763 14.8287 27.1644C14.7847 27.2526 14.7584 27.3485 14.7514 27.4468C14.7444 27.5452 14.7569 27.6439 14.788 27.7374C14.8192 27.8309 14.8685 27.9173 14.933 27.9918C14.9976 28.0662 15.0762 28.1272 15.1644 28.1713L19.6644 30.4213C19.7686 30.4734 19.8835 30.5006 20 30.5006C20.1165 30.5006 20.2314 30.4734 20.3356 30.4213L24.8356 28.1713C25.0137 28.0823 25.149 27.9262 25.212 27.7374C25.2749 27.5485 25.2603 27.3424 25.1713 27.1644C25.0822 26.9864 24.9261 26.851 24.7373 26.7881C24.5485 26.7251 24.3424 26.7398 24.1644 26.8288L20.75 28.5369V25.2154C22.7987 25.026 24.703 24.0789 26.0902 22.5594C27.4773 21.0399 28.2475 19.0575 28.25 17V13.25C28.25 13.0511 28.171 12.8604 28.0303 12.7197C27.8897 12.5791 27.6989 12.5 27.5 12.5ZM19.25 23.7088C17.6006 23.5223 16.0776 22.7355 14.971 21.4983C13.8645 20.261 13.2519 18.6599 13.25 17V14.0413C14.8994 14.2278 16.4224 15.0146 17.529 16.2518C18.6355 17.4891 19.2481 19.0902 19.25 20.75V23.7088ZM20 17.315C19.3691 15.9443 18.3759 14.7718 17.1275 13.9241C18.0247 12.4438 19.3775 11.496 20 11.1116C20.6234 11.4941 21.9762 12.4419 22.8725 13.9241C21.6243 14.772 20.6312 15.9445 20 17.315ZM26.75 17C26.7481 18.6599 26.1355 20.261 25.029 21.4983C23.9224 22.7355 22.3994 23.5223 20.75 23.7088V20.75C20.7519 19.0902 21.3645 17.4891 22.471 16.2518C23.5776 15.0146 25.1006 14.2278 26.75 14.0413V17Z"
      fill="#146CFA"
    />
  ]
});
