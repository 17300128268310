import { createIcon } from '@chakra-ui/react';

export const DiariasInternacaoHospitalar = createIcon({
  displayName: 'DiariasInternacaoHospitalar',
  viewBox: '0 0 40 40',
  path: [
    <rect
      key="1"
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="19.5"
      fill="none"
      stroke="#146CFA"
    />,
    <path
      key="2"
      d="M31.25 27.5H30.5V20C30.5 19.6022 30.342 19.2206 30.0607 18.9393C29.7794 18.658 29.3978 18.5 29 18.5H23.75V12.5C23.75 12.1022 23.592 11.7206 23.3107 11.4393C23.0294 11.158 22.6478 11 22.25 11H13.25C12.8522 11 12.4706 11.158 12.1893 11.4393C11.908 11.7206 11.75 12.1022 11.75 12.5V27.5H11C10.8011 27.5 10.6103 27.579 10.4697 27.7197C10.329 27.8603 10.25 28.0511 10.25 28.25C10.25 28.4489 10.329 28.6397 10.4697 28.7803C10.6103 28.921 10.8011 29 11 29H31.25C31.4489 29 31.6397 28.921 31.7803 28.7803C31.921 28.6397 32 28.4489 32 28.25C32 28.0511 31.921 27.8603 31.7803 27.7197C31.6397 27.579 31.4489 27.5 31.25 27.5ZM29 20V27.5H23.75V20H29ZM13.25 12.5H22.25V27.5H20.75V23C20.75 22.8011 20.671 22.6103 20.5303 22.4697C20.3897 22.329 20.1989 22.25 20 22.25H15.5C15.3011 22.25 15.1103 22.329 14.9697 22.4697C14.829 22.6103 14.75 22.8011 14.75 23V27.5H13.25V12.5ZM19.25 27.5H16.25V23.75H19.25V27.5ZM14.75 17C14.75 16.8011 14.829 16.6103 14.9697 16.4697C15.1103 16.329 15.3011 16.25 15.5 16.25H17V14.75C17 14.5511 17.079 14.3603 17.2197 14.2197C17.3603 14.079 17.5511 14 17.75 14C17.9489 14 18.1397 14.079 18.2803 14.2197C18.421 14.3603 18.5 14.5511 18.5 14.75V16.25H20C20.1989 16.25 20.3897 16.329 20.5303 16.4697C20.671 16.6103 20.75 16.8011 20.75 17C20.75 17.1989 20.671 17.3897 20.5303 17.5303C20.3897 17.671 20.1989 17.75 20 17.75H18.5V19.25C18.5 19.4489 18.421 19.6397 18.2803 19.7803C18.1397 19.921 17.9489 20 17.75 20C17.5511 20 17.3603 19.921 17.2197 19.7803C17.079 19.6397 17 19.4489 17 19.25V17.75H15.5C15.3011 17.75 15.1103 17.671 14.9697 17.5303C14.829 17.3897 14.75 17.1989 14.75 17Z"
      fill="#146CFA"
    />
  ]
});
