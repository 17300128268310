import { AssistenciaResidencialIcon } from '../assets/icons/beneficios/AssitenciaResidencialIcon';
import { ClubeVantagemIcon } from '../assets/icons/beneficios/ClubeVantagemIcon';
import { DescontoFarmaciaIcon } from '../assets/icons/beneficios/DescontoFarmaciaIcon';
import { FazTudoIcon } from '../assets/icons/beneficios/FazTudoIcon';
import { MedicoTelaIcon } from '../assets/icons/beneficios/MedicoTelaIcon';
import { OpiniaoMedicaIcon } from '../assets/icons/beneficios/OpiniaoMedicaIcon';
import { SorteioMensalIcon } from '../assets/icons/beneficios/SorteioMensalIcon';

export const mockedAdvantage = [
  {
    icon: SorteioMensalIcon,
    label: 'Sorteios mensais',
    __html:
      '<b>Você concorre a 5 mil reais pela loteria federal todo mês! Mesmo se for sorteado, continua participando dos próximos sorteios e tem a chance de ganhar mais vezes!</b><br/><br/><b>Como funciona: </b>No último sábado de cada mês você concorre a 5 mil reais pela loteria federal. Nós te enviaremos um número da sorte e avisaremos se você ganhar, mas você também pode conferir direto no site da loteria federal!<br/><br/><b>Atenção: </b>Você não pode estar com o pagamento em atraso para receber esse prêmio, ok? ',
    products: ['acidentes_pessoais']
  },
  {
    icon: SorteioMensalIcon,
    label: 'Sorteios mensais',
    __html:
      '<b>Você concorre a 15 mil reais pela loteria federal todo mês! Mesmo se for sorteado, continua participando dos próximos sorteios e tem a chance de ganhar mais vezes!</b><br/><br/><b>Como funciona: </b>No último sábado de cada mês você concorre a 15 mil reais pela loteria federal. Nós te enviaremos um número da sorte e avisaremos se você ganhar, mas você também pode conferir direto no site da loteria federal!<br/><br/><b>Atenção: </b>Você não pode estar com o pagamento em atraso para receber esse prêmio, ok? ',
    products: ['vida_flex']
  },
  {
    icon: ClubeVantagemIcon,
    label: 'Clube de vantagens',
    __html:
      '<b>Descontos e benefícios exclusivos para os clientes SulAmérica cuidarem da sua Saúde Integral do jeito certo: o seu! São inúmeras possibilidade para você cuidar da sua Saúde Física, Emocional e Financeira!</b><br/><br/><b>Como funciona: </b>Tudo de graça e, melhor ainda, sem li-mi-tes, é só acessar o espaço do cliente, resgatar e se cuidar.',
    products: ['acidentes_pessoais', 'vida_individual', 'vida_flex']
  },
  {
    icon: DescontoFarmaciaIcon,
    label: 'Descontos em farmácia',
    ids: [1181398408, 7295435071],
    __html:
      '<b>Ampla rede de farmácias que oferecem descontos de até 70% em diversos medicamentos nas maiores redes de farmácias do Brasil.</b><br/><br/><b>Como funciona: </b>Basta solicitar o desconto via SulAmérica na farmácia informando o seu CPF!<br/><br/><b>Farmácias e medicamentos: </b>São mais de 25mil farmácias com descontos em medicamentos, itens de higiene pessoal, perfumaria e beleza. São diversas redes de farmácias, como Droga Raia, Drogasil, Drogaria São Paulo, Onofre, Pacheco, Araújo, Pague Menos, Farmais, Panvel e outras.',
    products: ['acidentes_pessoais', 'vida_individual', 'vida_flex']
  },
  {
    icon: MedicoTelaIcon,
    label: ' Médico na tela individual',
    __html:
      '<b>Serviço de atendimento e orientação médica incluindo prescrição de receitas para medicamentos. Basta ligar na central de atendimento e acessar o link para sala de vídeo.</b><br/><br/><b>Como funciona:</b> Basta ligar para a Central de Atendimento 0800-726-3935 a qualquer momento. Você passará por triagem para chegar na especialidade médica necessária, receberá um link para a consulta por video-chamada e, se preciso, o médico enviará a receita para medicamentos no fim do atendimento.<br/><br/><b>Períodos: </b> O atendimento é disponibilizado 24 horas por dia e 7 dias da semana.<br/><br/><b>Sobre a receita digital:</b> Você poderá realizar a compra dos medicamentos com o QR Code e o código gerados na prescrição digital, que você receberá via e-mail ou SMS em até uma hora após o atendimento.',
    products: []
  },
  {
    icon: MedicoTelaIcon,
    label: ' Médico na tela familiar',
    __html:
      '<b>Serviço de atendimento e orientação médica para você, seus cônjuge e filhos. Basta ligar na central de atendimento e acessar o link para sala de vídeo.  Se necessário, prescrição de receitas para medicamentos.</b><br/><br/><b>Como funciona: </b>Basta ligar para a Central de Atendimento 0800-726-3935 a qualquer momento. Você passará por triagem para chegar na especialidade médica necessária, receberá um link para a consulta por vídeo-chamada e, se preciso, o médico enviará a receita para medicamentos no fim do atendimento.   <br/><br/><b>Períodos: </b>O atendimento é disponibilizado 24 horas por dia e 7 dias da semana.<br/><br/><b>Sobre a receita digital: </b>Você poderá realizar a compra dos medicamentos com o QR Code e o código gerados na prescrição digital, que você receberá via e-mail ou SMS em até uma hora após o atendimento.',
    products: []
  },
  {
    icon: OpiniaoMedicaIcon,
    label: ' 2ª Opinião médica',
    __html:
      '<b>Em caso de doenças complexas, o segurado terá direito a uma segunda opinião com um médico reconhecido internacionalmente.</b><br/><br/><b>Como funciona: </b>Basta ligar para a Central de Atendimento 4090 1073 (Capitais e Regiões Metropolitanas) ou 0800 778 1073 (Demais localidades)Escolha a opção Vida e em seguida o serviço que deseja acionar.',
    products: ['vida_individual', 'vida_flex']
  },
  {
    icon: AssistenciaResidencialIcon,
    label: ' Assistência residencial',
    __html:
      '<b>Caso a Segurada esteja hospitalizada, oferecemos o serviço de babysitter, transporte escolar e remoção médica para seu filho durante período coberto.</b><br/><br/><b>Como funciona: </b>Basta ligar para a Central de Atendimento 4090 1073 (Capitais e Regiões Metropolitanas) ou 0800 778 1073 (Demais localidades)Escolha a opção Vida e em seguida o serviço que deseja acionar.',
    products: ['vida_individual', 'vida_flex']
  },
  {
    icon: FazTudoIcon,
    label: 'Faz tudo',
    __html:
      '<b>Pequenos serviços domésticos como fixação de quadros, cortinas, prateleiras, olho mágico e muito mais.</b><br/><br/><b>Como funciona: </b>Basta ligar para a Central de Atendimento 4090 1073 (Capitais e Regiões Metropolitanas) ou 0800 778 1073 (Demais localidades)Escolha a opção Vida e em seguida o serviço que deseja acionar.',
    products: ['vida_individual', 'vida_flex']
  }
];