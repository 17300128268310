import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import sslLogo from '../assets/images/ssl-logo.png';
import { ButtonWhatsApp } from '../components/buttons/ButtonWhatsApp';
import { CardsHomeForMoreProducts } from '../components/cards/CardsHomeForMoreProducts';
import { CardsHomeForOneOrTwoProduct } from '../components/cards/CardsHomeForOneOrTwoProduct';
import { Footer } from '../components/layouts/Footer';
import { Header } from '../components/layouts/Header';
import { LayoutFooter } from '../components/layouts/LayoutFooter';
import { PageLoading } from '../components/loading/pageLoading';
import { useInstantRedirectProdId } from '../hooks/useInstantRedirectProdId';
import { useSubdomain } from '../hooks/useSubdomain';
import { loadWebsiteData } from '../services/home.service';
import { wlLocalStorage } from '../utils/localStorage.util';
import { MAX_NUMBER_COVERAGES } from '../utils/products.utils';
import { compare } from '../utils/sorting.util';
import { removeDiacritic } from '../utils/string-utils';

type HomeProps = {
  isPreview?: boolean;
};

export const Home = ({ isPreview }: HomeProps) => {
  // console.log('ambiente node', process.env.NODE_ENV);
  // console.log('ambiente node 2', process.env.REACT_APP_NODE_ENV);
  const subdomain = useSubdomain() || 'M';
  const redirectProdId = useInstantRedirectProdId();
  const [urlSearchParams] = useSearchParams();

  const previewToken = urlSearchParams.get('token');
  const subdomainQueryParam = urlSearchParams.get('subdominio');

  const [MaxCoversNumber, setMaxCoversNumber] = useState(0);

  const compareProducts = (a: any, b: any) => {
    return compare(a, b, 'order_index');
  };

  const [websiteData, setWebsiteData] = useState({
    id: '',
    firstTitle: '',
    secondTitle: '',
    firstSubtitle: '',
    secondSubtitle: '',
    website_logo_url: '',
    banner_first_sec_url: '',
    banner_second_sec_url: '',
    websiteColor: '',
    linkedin: null,
    instagram: null,
    youtube: null,
    twitter: null,
    facebook: null,
    whatsapp: null,
    nome_corretor: '',
    percentual_corretagem: 2,
    percentual_agenciamento: 100,
    percentual_intermediacao: 0,
    logo_escrita: '',
    status: '',
    logo_sulamerica: false
  });
  const [dataProducts, setDataProducts] = useState([
    {
      title: '',
      subtitle: '',
      text: '',
      price: 0,
      assistences: [
        {
          assist: ''
        },
        {
          assist: ''
        },
        {
          assist: ''
        }
      ],
      covers: [],
      recommended: false,
      percentual_corretagem: 2,
      percentual_agenciamento: 100,
      percentual_intermediacao: 0,
      id: '',
      product_id: '',
      minValue: 0,
      codigo_corretor: 0,
      codigo_estrutura_venda: 0,
      codigo_estrutura_venda_parceria: 0,
      maxValue: 0,
      initialValue: 10000,
      quotationDatas: null
    }
  ]);

  const query = useQuery(
    ['load_preview_website'],
    () =>
      loadWebsiteData({
        subdomain,
        isPreview: !!(
          (previewToken && isPreview) ||
          (process.env.REACT_APP_NODE_ENV === 'development' && isPreview)
        ),
        previewToken
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: (data) => {
        console.log(data.website);

        wlLocalStorage.setItem('subdomain', subdomain);
        wlLocalStorage.setItem(
          'websiteColor',
          data.website.websiteColor || '#FF5000'
        );
        wlLocalStorage.setItem('nomeCorretor', data.website.nome_corretor);
        wlLocalStorage.setItem('id_website', data.website.id);

        const socialMidias = {
          facebook: data.website.facebook,
          instagram: data.website.instagram,
          linkedin: data.website.linkdin,
          twitter: data.website.twitter,
          youtube: data.website.youtube
        };

        wlLocalStorage.setItem('social-midias', JSON.stringify(socialMidias));

        const products = data.products.sort(compareProducts);
        setDataProducts(products);

        let maxCoversNumber = 0;
        products.forEach((item: any) => {
          if (item.covers.length > maxCoversNumber) {
            maxCoversNumber = item.covers.length;
          }
        });

        if (maxCoversNumber > MAX_NUMBER_COVERAGES) {
          maxCoversNumber = MAX_NUMBER_COVERAGES;
        }

        setMaxCoversNumber(maxCoversNumber);

        setWebsiteData({
          ...data.website,
          websiteColor: data.website.websiteColor ?? '#FF5000'
        });
      },
      onError: (error) => console.log(error)
    }
  );

  const cardsRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const cardForMoreProductRef =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  const widthScreen = window.screen.width;

  useEffect(() => {
    const accessTokenBFF = localStorage.getItem('accessTokenBFF');
    localStorage.clear();
    wlLocalStorage.clear();
    if (accessTokenBFF) {
      localStorage.setItem('accessTokenBFF', accessTokenBFF);
    }
  }, []);

  if (isPreview && process.env.REACT_APP_NODE_ENV != 'development') {
    const redirect = subdomainQueryParam
      ? `../?subdominio=${subdomain}`
      : '../';
    if (!previewToken) return <Navigate to={redirect} />;
  }

  if (query.isLoading || query.isError) {
    return <PageLoading hasError={query.isError} />;
  }

  return (
    <>
      {/* {loading && (
        <ModalLoad isOpenModal={loading} content={'Buscando produtos...'} />
      )} */}
      <Global styles={`body { background-color: white; }`} />
      <Box
        className="home-wrapper"
        display={redirectProdId ? 'none' : undefined}
      >
        {isPreview ? (
          <Flex
            w="100%"
            h="30px"
            bg="#1d50b5"
            alignItems="center"
            justifyContent="center"
            py={2}
            gap={2}
          >
            <InfoOutlineIcon color="white" />
            <Text color="white" fontSize="0.8rem">
              Você está visualizando uma versão de prévia deste site.
            </Text>
          </Flex>
        ) : undefined}
        <Header
          logo={websiteData.website_logo_url}
          whatsapp={websiteData.whatsapp}
          logo_escrita={websiteData.logo_escrita}
          logo_sulamerica={websiteData.logo_sulamerica}
        />
        {!(websiteData.firstTitle == '') && (
          <>
            <Box w="100%" aria-label="sec-secao-1">
              <Box aria-label="container" w="100%" m="0 !important">
                <Stack w="100%" position="relative">
                  <Box
                    p={['30px 32px', '30px 38px', '50px 38px 50px 74px']}
                    bg={websiteData.websiteColor}
                    w={['100%', '90%', '90%']}
                    h={['', '', '390px']}
                    borderRadius={[
                      '0px 0px 100px 0px',
                      '0px 0px 100px 0px',
                      '0px 180px 180px 0px'
                    ]}
                    mt={['130px', '130px', '30px']}
                  >
                    <Box
                      width={['100%', '100%', '45%']}
                      mt={['122px', '122px', '0']}
                    >
                      <Text
                        color="#FFFFFF"
                        fontSize="2rem"
                        fontWeight="700"
                        lineHeight="2.5rem"
                      >
                        {websiteData.firstTitle}
                      </Text>
                      <Text
                        fontSize="1rem"
                        lineHeight="1.5rem"
                        color="white"
                        mt="20px"
                      >
                        {websiteData.firstSubtitle}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    borderRadius="180px 0px 0px 180px"
                    overflow="hidden"
                    m="0 !important"
                    position="absolute"
                    top="0px"
                    right="0px"
                    maxW="800px"
                    w={['100%', '100%', '55%']}
                    h={['260px', '260px', '360px']}
                  >
                    {websiteData.banner_first_sec_url && (
                      <Image
                        src={websiteData.banner_first_sec_url}
                        w="100%"
                        h="100%"
                      />
                    )}
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Box
              m={[
                '30px 0 32px 0 !important',
                '30px 0 32px 0 !important',
                '70px 0 40px 0 !important'
              ]}
              w="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              aria-label="sec-planos"
            >
              <HStack
                ref={cardsRef}
                p={[
                  '0 32px !important',
                  '0 32px !important',
                  '0 74px !important'
                ]}
                w="100%"
                display="flex"
                flexDirection={
                  dataProducts.length >= 2 && widthScreen < 768
                    ? 'row'
                    : dataProducts.length >= 3
                    ? 'row'
                    : 'column'
                }
                justifyContent={
                  dataProducts.length >= 2 && widthScreen <= 1024
                    ? 'start'
                    : dataProducts.length >= 4
                    ? 'start'
                    : 'center'
                }
                gridGap={['16px', '16px', '32px']}
                overflowX={widthScreen > 768 ? 'hidden' : 'auto'}
              >
                {!(
                  dataProducts[0].title == '' && websiteData.websiteColor == ''
                ) &&
                  dataProducts.map((item, index) => {
                    const assistences = item.assistences || [];

                    const medNaTelaArr: any[] = [];
                    const restArr: any[] = [];
                    assistences.forEach((el) => {
                      if (
                        removeDiacritic(el.assist)
                          .toLowerCase()
                          .includes('medico na tela')
                      ) {
                        medNaTelaArr.push(el);
                      } else {
                        restArr.push(el);
                      }
                    });
                    const orderedAssistences = [
                      ...medNaTelaArr.concat(restArr)
                    ]; //certificanco que um novo array está sendo criado

                    if (dataProducts.length <= 2 && widthScreen >= 768) {
                      return (
                        <CardsHomeForOneOrTwoProduct
                          key={index}
                          product={item}
                          redirectProdId={redirectProdId}
                          id={item.id}
                          subtitle={item.subtitle}
                          price={item.price}
                          text={item.text}
                          title={item.title}
                          assistences={orderedAssistences}
                          covers={item.covers}
                          recommended={item.recommended}
                          quotationDatas={item.quotationDatas}
                          quotationMin={Number(item.minValue)}
                          quotationMax={Number(item.maxValue)}
                          websiteId={websiteData.id}
                          websiteStatus={websiteData.status}
                          product_id={item.product_id}
                          codigo_corretor={Number(item.codigo_corretor)}
                          codigo_estrutura_venda={Number(
                            item.codigo_estrutura_venda_parceria &&
                              (websiteData.percentual_intermediacao ||
                                item.percentual_intermediacao)
                              ? item.codigo_estrutura_venda_parceria
                              : item.codigo_estrutura_venda
                          )}
                          corretagem={
                            item.percentual_corretagem ||
                            websiteData.percentual_corretagem
                          }
                          agenciamento={
                            item.percentual_agenciamento ||
                            websiteData.percentual_agenciamento
                          }
                          intermediacao={
                            item.codigo_estrutura_venda_parceria &&
                            ((item.percentual_intermediacao
                              ? item.percentual_intermediacao
                              : undefined) ||
                              (websiteData.percentual_intermediacao
                                ? websiteData.percentual_intermediacao
                                : undefined))
                          }
                          initialValue={Number(item.initialValue)}
                          color={websiteData.websiteColor}
                          disabled={!!isPreview}
                        />
                      );
                    } else {
                      return (
                        <CardsHomeForMoreProducts
                          reference={cardForMoreProductRef}
                          key={index}
                          id={item.id}
                          redirectProdId={redirectProdId}
                          product={item}
                          subtitle={item.subtitle}
                          price={item.price}
                          text={item.text}
                          title={item.title}
                          assistences={orderedAssistences}
                          covers={item.covers}
                          recommended={item.recommended}
                          quotationDatas={item.quotationDatas}
                          quotationMin={Number(item.minValue)}
                          quotationMax={Number(item.maxValue)}
                          websiteId={websiteData.id}
                          websiteStatus={websiteData.status}
                          product_id={item.product_id}
                          codigo_corretor={Number(item.codigo_corretor)}
                          codigo_estrutura_venda={Number(
                            item.codigo_estrutura_venda_parceria &&
                              (websiteData.percentual_intermediacao ||
                                item.percentual_intermediacao)
                              ? item.codigo_estrutura_venda_parceria
                              : item.codigo_estrutura_venda
                          )}
                          corretagem={
                            item.percentual_corretagem ||
                            websiteData.percentual_corretagem
                          }
                          agenciamento={
                            item.percentual_agenciamento ||
                            websiteData.percentual_agenciamento
                          }
                          intermediacao={
                            item.codigo_estrutura_venda_parceria &&
                            ((item.percentual_intermediacao
                              ? item.percentual_intermediacao
                              : undefined) ||
                              (websiteData.percentual_intermediacao
                                ? websiteData.percentual_intermediacao
                                : undefined))
                          }
                          initialValue={Number(item.initialValue)}
                          color={websiteData.websiteColor}
                          disabled={!!isPreview}
                          maxCoveragesNumber={MaxCoversNumber}
                        />
                      );
                    }
                  })}
              </HStack>
              <HStack>
                {(dataProducts.length >= 3 &&
                  widthScreen > 768 &&
                  widthScreen <= 1024) ||
                (dataProducts.length >= 4 && widthScreen > 768)
                  ? dataProducts.map((item, index) => {
                      if (index % 2 == 0) {
                        return (
                          <input
                            data-testid="scroll-radio"
                            key={index}
                            name="scroll-radio"
                            type="radio"
                            style={{ marginTop: '30px' }}
                            onClick={() => {
                              const elScroll = cardsRef.current;
                              console.log(elScroll);

                              if (elScroll) {
                                elScroll.scrollTo(
                                  index *
                                    cardForMoreProductRef.current?.scrollWidth,
                                  0
                                );
                              }
                            }}
                          />
                        );
                      }
                    })
                  : null}
              </HStack>
            </Box>
            <Box
              w="100%"
              aria-label="sec-secao-2"
              borderTop="0px -2px 5px #D2D2D2 !important"
            >
              <Box aria-label="container" w="100%" m="0 !important">
                <Stack w="100%" position="relative">
                  <Box
                    borderRadius="0px 180px 180px 0px"
                    overflow="hidden"
                    m="0 !important"
                    position="absolute"
                    top="0px"
                    maxW="800px"
                    w={['100%', '100%', '55%']}
                    h={['260px', '260px', '360px']}
                  >
                    {websiteData.banner_second_sec_url && (
                      <Image
                        src={websiteData.banner_second_sec_url}
                        w="100%"
                        h="100%"
                      />
                    )}
                  </Box>
                  <Box
                    p={['30px 32px', '30px 38px', '50px 74px 50px 38px']}
                    bg={websiteData.websiteColor}
                    w={['100%', '90%', '90%']}
                    h={['', '', '390px']}
                    borderRadius={[
                      '0px 0px 0px 100px',
                      '0px 0px 0px 100px',
                      '180px 0px 0px 180px'
                    ]}
                    mt={[
                      '130px !important',
                      '130px!important',
                      '30px !important'
                    ]}
                    ml={['', '', '10% !important']}
                    display="flex"
                    flexDirection="column"
                    alignItems="end"
                    justifyContent="space-between"
                  >
                    <Box
                      width={['100%', '100%', '45%']}
                      mt={['122px', '122px', '0']}
                      textAlign="end"
                    >
                      <Text
                        color="#FFFFFF"
                        fontSize="2rem"
                        fontWeight="700"
                        lineHeight="2.5rem"
                      >
                        {websiteData.secondTitle}
                      </Text>
                      <Text
                        fontSize="1rem"
                        lineHeight="1.5rem"
                        color="white"
                        mt="20px"
                      >
                        {websiteData.secondSubtitle}
                      </Text>
                    </Box>
                    <Box
                      w="100%"
                      mt={['10px', '10px', '0px']}
                      mb="10px"
                      position="relative"
                      display="flex"
                      justifyContent={['center', 'end']}
                    >
                      <ButtonWhatsApp
                        numberWhatsApp={`+55${websiteData.whatsapp}`}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Box>
            <LayoutFooter items={sslLogo}>
              <Footer
                facebook={websiteData.facebook}
                instagram={websiteData.instagram}
                linkedin={websiteData.linkedin}
                twitter={websiteData.twitter}
                youtube={websiteData.youtube}
              />
            </LayoutFooter>
          </>
        )}
      </Box>
    </>
  );
};
