import { AxiosResponse } from 'axios';

import { apiSensedia } from '../api/api-sensedia';
import { wlLocalStorage } from '../utils/localStorage.util';
import { delay } from '../utils/time-utils';

export const loadWebsiteData = async ({
  subdomain,
  isPreview,
  previewToken
}: {
  subdomain: string;
  isPreview: boolean | undefined;
  previewToken?: string | null | undefined;
}) => {
  let headers = undefined;
  if (previewToken) {
    headers = { token: previewToken };
  }
  const sensediaApi = await apiSensedia();
  const previewAppendUrl = isPreview ? '/previa' : '/conteudo';
  let looping = true;
  let response = {} as AxiosResponse;
  let ocorreuGatewayTimeOut = false;
  while (looping) {
    try {
      if (ocorreuGatewayTimeOut) {
        await delay(4500)
      }
      response = await sensediaApi.get(
        `/v1/websites/${subdomain}${previewAppendUrl}`,
        {
          headers: { ...headers, 'first-try': !ocorreuGatewayTimeOut }
        }
      );
      looping = false;
    } catch (e: any) {
      console.log('dados erro', e);
      if (e.response.status == 500) {
        console.log('ocorreu internal server error', e.response.statusText);
        console.log('ocorreu internal server error', e.response.data);
      } else {
        console.log('ocorreu gateway timeout', e.response.data);
        ocorreuGatewayTimeOut = true;
      }
      if (e.response.status != 504) {
        looping = false;
        ocorreuGatewayTimeOut = false;
      }
    }
  }



  console.log('ids produtos', response.data.products?.map((el: any) => ({ id: el.product_id, nome: el.text })));

  response.data?.website?.hidden_assists && wlLocalStorage.setItem('hidden_assists', JSON.stringify(response.data.website.hidden_assists))

  return {
    website: response.data.website,
    products: response.data.products
  };
};
