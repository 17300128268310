import { createIcon } from '@chakra-ui/react';

export const CirurgiasIcon = createIcon({
  displayName: 'CirurgiasIcon',
  viewBox: '0 0 40 40',
  path: [
    <rect
      key="1"
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="19.5"
      fill="none"
      stroke="#146CFA"
    />,
    <path
      key="2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8948 10.6251C28.5858 10.6251 29.2487 10.8992 29.7379 11.3872C30.2254 11.8765 30.4991 12.5391 30.4991 13.2298C30.4991 13.9205 30.2254 14.5831 29.7379 15.0725L25.3739 19.4375L27.2854 21.3472C27.415 21.4767 27.4926 21.6493 27.5037 21.8322C27.5147 22.0151 27.4584 22.1957 27.3454 22.34C27.3435 22.3424 27.3404 22.3461 27.3363 22.3512C27.1799 22.5449 25.4652 24.6684 22.5642 26.5409C19.6551 28.4187 16.5642 29.375 13.4751 29.375C12.3328 29.3744 11.1942 29.2439 10.0814 28.9859C9.95094 28.9558 9.83094 28.8913 9.73383 28.7992C9.63672 28.7071 9.56603 28.5907 9.52911 28.462C9.49218 28.3333 9.49036 28.1971 9.52384 28.0675C9.55732 27.9379 9.62487 27.8197 9.71949 27.725L26.0517 11.3872C26.5409 10.8992 27.2038 10.6251 27.8948 10.6251ZM21.7101 25.3062C23.1884 24.3519 24.5387 23.2127 25.7282 21.9162L23.2189 19.4063L24.2798 18.3453L24.3126 18.3781L28.6776 14.0131C28.8851 13.8056 29.0017 13.5232 29.0017 13.2298C29.0017 12.9364 28.8851 12.655 28.6776 12.4475C28.4701 12.24 28.1887 12.1234 27.8953 12.1234C27.6018 12.1234 27.3204 12.24 27.1129 12.4475L22.7489 16.8125L22.7807 16.8444L21.719 17.9061L21.6876 17.8747L11.7942 27.7718C15.1532 28.205 18.4823 27.3781 21.7101 25.3062Z"
      fill="#146CFA"
    />
  ]
});
