import { createIcon } from '@chakra-ui/react';

export const MorteIcon = createIcon({
  displayName: 'MorteIcon',
  viewBox: '0 0 48 50',
  path: [
    <rect
      key="1"
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="19.5"
      fill="none"
      stroke="#146CFA"
    />,
    <path
      key="2"
      d="M26.75 14.75H23V11C23 10.6022 22.842 10.2206 22.5607 9.93934C22.2794 9.65804 21.8978 9.5 21.5 9.5H18.5C18.1022 9.5 17.7206 9.65804 17.4393 9.93934C17.158 10.2206 17 10.6022 17 11V14.75H13.25C12.8522 14.75 12.4706 14.908 12.1893 15.1893C11.908 15.4706 11.75 15.8522 11.75 16.25V19.25C11.75 19.6478 11.908 20.0294 12.1893 20.3107C12.4706 20.592 12.8522 20.75 13.25 20.75H17V29C17 29.3978 17.158 29.7794 17.4393 30.0607C17.7206 30.342 18.1022 30.5 18.5 30.5H21.5C21.8978 30.5 22.2794 30.342 22.5607 30.0607C22.842 29.7794 23 29.3978 23 29V20.75H26.75C27.1478 20.75 27.5294 20.592 27.8107 20.3107C28.092 20.0294 28.25 19.6478 28.25 19.25V16.25C28.25 15.8522 28.092 15.4706 27.8107 15.1893C27.5294 14.908 27.1478 14.75 26.75 14.75ZM26.75 19.25H22.25C22.0511 19.25 21.8603 19.329 21.7197 19.4697C21.579 19.6103 21.5 19.8011 21.5 20V29H18.5V20C18.5 19.8011 18.421 19.6103 18.2803 19.4697C18.1397 19.329 17.9489 19.25 17.75 19.25H13.25V16.25H17.75C17.9489 16.25 18.1397 16.171 18.2803 16.0303C18.421 15.8897 18.5 15.6989 18.5 15.5V11H21.5V15.5C21.5 15.6989 21.579 15.8897 21.7197 16.0303C21.8603 16.171 22.0511 16.25 22.25 16.25H26.75V19.25Z"
      fill="#146CFA"
    />
  ]
});
